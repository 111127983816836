<template>
  <div ref="student" class="student">
    <keep-alive>
      <list-template
          :current-page="page"
          :page-size="20"
          :table-config="tableConfig"
          :table-data="tableData"
          :total="total"
          :loading="loading"
          @onChangePage="changeCurrentPage"
          @onReset="search = null"
          @onSearch="onSearch"
      >
        <template slot="title">
          <search-block
              :search-loading="loading"
              ref="search" :search-config="searchConfig" @onReset="search = {}" @onSearch="onSearch">
            <template slot="search">
              <el-button size="mini" style="margin-left: 18rem" type="primary" @click="onExportData">导出数据</el-button>
            </template>
          </search-block>
        </template>
      </list-template>
    </keep-alive>
  </div>
</template>

<script>
import ListTemplate from '@/components/pages/List'
import SearchBlock from '@/components/SearchBlock'
import { mapState } from 'vuex'
import tools from 'utils/tools'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  data() {
    let defaultDate = this.findDate()
    return {
      loading:false,
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'start_end_date',
          placeholder: '筛选时间段',
          tag: 'datePicker',
          property: {
            clearable: false,
            type: 'daterange',
            size: 'small',
            style: 'margin-right:20rem;',
            rangeSeparator: '-',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd'
          },
          default: defaultDate
        },
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag: 'select',
          options: [],
          value: 'id',
          label: 'school_name',
          default: -1
        },
        {
          prop: 'grade_id',
          placeholder: '筛选年级',
          tag: 'select',
          options: [],
          value: 'id',
          label: 'name',
          default: -1
        }
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'teacher_name',
          label: '班主任姓名'
        },
        {
          prop: 'class_name',
          label: '行政班'
        },
        {
          prop: 'student_num',
          label: '当前班级人数'
        },
        {
          prop: 'grade_name',
          label: '年级'
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'moral_num',
          label: '德育辅导人数'
        },
        {
          prop: 'week_num',
          label: '班级周报次数'
        }
      ],
      search: {
        start_end_date: defaultDate,
        school_id: -1,
        grade_id: -1
      },
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData: []
    }
  },
  computed: {
    ...mapState(['page'])
  },
  components: {
    ListTemplate,
    SearchBlock
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    // 获取数据
    getData() {
      let { search, page } = this
      let params = {
        ...search,
        page
      }
      if (params.start_end_date) {
        params.start_end_date = params.start_end_date.join(' ~ ')
      }
      this.loading = true;
      this.$_axios2.get('api/teacher/moral-statistic/tea-work-detail', { params }).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(()=>this.loading = false)
    },
    // 搜索功能
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    // 表格数据导出
    onExportData() {
      let { search, page } = this
      let params = {
        ...search,
        page,
        is_export: 1
      }
      if (params.start_end_date) {
        params.start_end_date = params.start_end_date.join('~')
      }
      this.$_axios2.get('api/teacher/moral-statistic/tea-work-detail', { params }).then(res => {
        tools.download('班主任工作明细', res.data)
      })
    },
    // 获取今天之前多少天的日期
    findDate() {
      let date = new Date();
      let preDate = new Date(date.getTime());
      let preDateTo = new Date(date.getTime() - (24 * 60 * 60 * 1000) * 9);
      return [preDateTo.Format('yyyy-MM-dd'), preDate.Format('yyyy-MM-dd')]
    }
  },
  created() {
    // 校区数据获取
    this.$_axios.get('site/school').then(res => {
      this.searchConfig[1].options = res.data.data
      this.searchConfig[1].options.unshift({
        id: -1,
        school_name: '全部校区'
      })
    })
    // 年级获取
    this.$_axios.get('site/grades').then(res => {
      this.searchConfig[2].options = res.data.data
      this.searchConfig[2].options.unshift({
        id: -1,
        name: '全部年级'
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.t-top {
  position: relative;
}
</style>
